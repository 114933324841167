jQuery(document).ready(function( $ ) {
    // Menu
    function showHideMenu(){
      $(".overlay").fadeToggle("slow");
      $(".hamburger").toggleClass('is-active');
    }
    
    $(".hamburger").on("click", function() {
      showHideMenu();
    });
    
    $(".overlay").on("click", function() {
      showHideMenu();
    });

  });
